<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">办公系统</el-breadcrumb-item>
        <el-breadcrumb-item>流程申请</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <el-tabs type="border-card">
      <el-tab-pane label="财务流程" class="horizbox">
        <el-row style="min-width:320px">
          <el-col :sm="6" :lg="4">
            <div class="el-result" @click="openFlowPage('expense')">
              <div class="el-result__icon">
                <el-icon style="font-size: 4em; color: #67c23a;">
                  <Ticket />
                </el-icon>
              </div>
              <div class="el-result__title">
                <p>费用报销单</p>
              </div>
              <div class="el-result__subtitle">
                <p>适用于申请人已垫资产生花销的费用</p>
              </div>
            </div>
          </el-col>
          <el-col :sm="6" :lg="4">
            <div class="el-result" @click="openFlowPage('payreceipt')">
              <div class="el-result__icon">
                <el-icon style="font-size: 4em; color: #e6a23c;">
                  <Money />
                </el-icon>
              </div>
              <div class="el-result__title">
                <p>请款申请单</p>
              </div>
              <div class="el-result__subtitle">
                <p>适用于对外部要付款的费用项</p>
              </div>
            </div>
          </el-col>
          <el-col :sm="6" :lg="4">
            <div class="el-result" @click="openFlowPage('loanapply')">
              <div class="el-result__icon">
                <el-icon style="font-size: 4em; color: #f56c6c;">
                  <Wallet />
                </el-icon>
              </div>
              <div class="el-result__title">
                <p>借/还款申请单</p>
              </div>
              <div class="el-result__subtitle">
                <p>可先进行费用借款申请，后续还款。</p>
              </div>
            </div>
          </el-col>
          <el-col :sm="6" :lg="4">
            <div class="el-result" @click="openFlowPage('bookkeep')">
              <div class="el-result__icon">
                <el-icon style="font-size: 4em; color: rgb(194, 0, 232);">
                  <CircleCheck />
                </el-icon>
              </div>
              <div class="el-result__title">
                <p>内部往来记账</p>
              </div>
              <div class="el-result__subtitle">
                <p>财务部使用流程，一般仅财务使用</p>
              </div>
            </div>
          </el-col>
          <el-col :sm="6" :lg="4">
            <div class="el-result" @click="openFlowPage('payback')">
              <div class="el-result__icon">
                <el-icon style="font-size: 4em; color: rgb(0, 143, 108);">
                  <CreditCard />
                </el-icon>
              </div>
              <div class="el-result__title">
                <p>收支确认单</p>
              </div>
              <div class="el-result__subtitle">
                <p>渠道订单回款/退差价等打款到公司账户</p>
              </div>
            </div>
          </el-col>
          <el-col :sm="6" :lg="4">
            <div class="el-result" @click="openFlowPage('equally')">
              <div class="el-result__icon">
                <el-icon style="font-size: 4em; color: gold;">
                  <DocumentCopy />
                </el-icon>
              </div>
              <div class="el-result__title">
                <p>费用分摊单据</p>
              </div>
              <div class="el-result__subtitle">
                <p>各部门的公共分摊费用，如水电及其他等</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="人事流程">
        <el-row style="min-width:320px;display: none;">
          <el-col :sm="6" :lg="4">
            <div class="el-result" @click="openFlowPage('hrleave')">
              <div class="el-result__icon">
                <el-icon style="font-size: 4em; color: rgb(69, 52, 254);">
                  <Moon />
                </el-icon>
              </div>
              <div class="el-result__title">
                <p>请假申请单</p>
              </div>
              <div class="el-result__subtitle">
                <p>适用于病假/产假/调休/婚假等休假情况</p>
              </div>
            </div>
          </el-col>
          <el-col :sm="6" :lg="4">
            <div class="el-result" @click="openFlowPage('hrovertime')">
              <div class="el-result__icon">
                <el-icon style="font-size: 4em; color: rgb(11, 168, 0);">
                  <OfficeBuilding />
                </el-icon>
              </div>
              <div class="el-result__title">
                <p>加班申请单</p>
              </div>
              <div class="el-result__subtitle">
                <p>适用于日常加班/节假日加班等情况</p>
              </div>
            </div>
          </el-col>
          <el-col :sm="6" :lg="4">
            <div class="el-result" @click="openFlowPage('hrnosign')">
              <div class="el-result__icon">
                <el-icon style="font-size: 4em; color: rgb(255, 0, 0);">
                  <AddLocation />
                </el-icon>
              </div>
              <div class="el-result__title">
                <p>补卡申请单</p>
              </div>
              <div class="el-result__subtitle">
                <p>适用于上下班漏卡等情况</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="其他流程">

      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
export default {
  name: "application",
  components: {},
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    openFlowPage(page) {
      this.$router.push("flowForm?page=" + (page || ""))
    }
  },
};
</script>
<style scoped>
.el-result {
  margin: 0 12px;
  border: 1px solid #fff;
  --el-result-padding: 20px;
  background: #fcfcfc;
}

.el-result:hover {
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
}
</style>